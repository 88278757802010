import React from "react";
import { Helmet } from "react-helmet";
import ECFrontComponent from "components/Ecommerce/ECView/ECFrontComponent";
import { BasketProvider } from "containers/ProductContainer/constant";
import "./style.css";

const EcommerceContainer = () => {
  return (
    <BasketProvider>
      <Helmet>
        <title>Ecommerce | PocketPay Payment</title>
        <meta name="description" content="MTC" />
      </Helmet>
      <ECFrontComponent />
    </BasketProvider>
  );
};

export default EcommerceContainer;
