import React, { useState } from "react";
import { PocketpayLogo, CancelIcon, ECartIcon, DropdownIcon } from "svgIcon";
import BasketComponent from "components/Basket/BasketComponent";
import { Button, Image, Menu } from "antd";
import { Dropdown, Space } from "antd";
import { baseImageURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.svg";
import MonoIcon from "assets/images/mono.jpg";
import ECProductComponent from "components/Ecommerce/ECProductComponent";
import ECAddressComponent from "components/Ecommerce/ECBilling/ECAddressComponent";
import ECHeroSectionComponent from "components/Ecommerce/ECView/ECHeroSectionComponent";
import { useBasket } from "containers/ProductContainer/constant";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ECBasicComponent = ({
  merchantContent,
  chainList,
  ecommerceContent,
}: any) => {
  const [showProducts, setShowProducts] = useState(false);
  const [showShippingLayout, setShowShippingLayout] = useState(false);
  const [showSideBasket, setShowSideBasket] = useState(false);

  const itemlist = useBasket();

  let length = 0;
  if (itemlist && itemlist.itemList) {
    length = itemlist.itemList.length;
  }

  return (
    <div
      className={
        showProducts ? "ec-component-wrapper inner" : "ec-component-wrapper"
      }
    >
      <ToastContainer />
      <div className="ec-header-wrapper">
        {Object.keys(merchantContent).length > 0 &&
        merchantContent.logo_path !== null ? (
          <Image
            src={MonoIcon}
            alt="profile Picture"
            preview={false}
            onClick={() => setShowProducts(false)}
          />
        ) : (
          <Image src={MonoIcon} alt="profile Picture" preview={false} />
        )}
        <div className="ec-header-wrapper-right">
          {/* {chainList && chainList.length > 0 && (
            <Dropdown overlay={<Menu>{chainList}</Menu>} className="dropDown">
              <div onClick={(e) => e.preventDefault()}>
                <Space className="subInnerHeading">
                  Supported Blockchain <DropdownIcon />
                </Space>
              </div>
            </Dropdown>
          )} */}
          {!showShippingLayout &&
            (showSideBasket ? (
              <div
                className="cross-icon"
                onClick={() => setShowSideBasket(false)}
              >
                <CancelIcon />
              </div>
            ) : (
              <div
                className={length > 0 ? "basket-icon active" : "basket-icon"}
                onClick={() => setShowSideBasket(true)}
              >
                <ECartIcon />
                <span className="ePNum">{length > 0 && length}</span>
              </div>
            ))}
        </div>
        {showSideBasket && (
          <BasketComponent
            setShowSideBasket={setShowSideBasket}
            showSideBasket={showSideBasket}
            setShowShippingLayout={setShowShippingLayout}
          />
        )}
      </div>
      <div className="mainPage ecProduct">
        {showShippingLayout ? (
          <ECAddressComponent setShowShippingLayout={setShowShippingLayout} />
        ) : (
          <>
            {showProducts ? (
              <ECProductComponent
                setShowProducts={setShowProducts}
                ecommerceContent={ecommerceContent}
              />
            ) : (
              <ECHeroSectionComponent
                setShowProducts={setShowProducts}
                ecommerceContent={ecommerceContent}
              />
            )}
          </>
        )}
      </div>
      <div className="ec-footer-wrapper">
        <PocketpayLogo />
        <p className="subInnerHeadingPara">@2024</p>
      </div>
    </div>
  );
};

export default ECBasicComponent;
