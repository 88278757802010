import React from "react";
import BasketItemComponent from "../BasketItemComponent";

const BasketComponent = ({ showSideBasket, setShowShippingLayout, setShowSideBasket }: any) => {
  return (
    <div
      className={`${
        showSideBasket ? "basketWrapper activeBasketWrapper" : "basketWrapper"
      }`}
    >
      <p className="subHeading">Basket</p>
      <BasketItemComponent setShowShippingLayout={setShowShippingLayout} setShowSideBasket={setShowSideBasket}/>
    </div>
  );
};

export default BasketComponent;
