import { ReactNode, createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import {
  AptosIcon,
  CkBTCIcon,
  CkETHIcon,
  ICPIcon,
  JupIcon,
  SolanaIcon,
  SolanaUsdcIcon,
  SuiIcon,
  VenomIcon,
} from "svgIcon";
import { Image } from "antd";
import FlowxIcon from "assets/images/FlowX.png";
import FudIcon from "assets/images/FUD.png";
import NavxIcon from "assets/images/navX.png";
import SacabamIcon from "assets/images/Sacabam.png";

export const ChainSUI = "SUI";
export const ChainSuiCoin = "SUI COIN";
export const ChainSuiFud = "FUD";
export const ChainSuiFlowx = "FlowX";
export const ChainSuiUsdc = "USDC";
export const ChainSuiSacabam = "Sacabam";
export const ChainSuiNavx = "NAVX";
export const ChainAptos = "Aptos";
export const ChainVenom = "Venom";
export const ChainSolana = "Solana";
export const ChainSOL = "SOL";
export const ChainSolanaUSDC = "SOL-USDC";
export const ChainSolanaJUP = "JUP";
export const ChainICP = "ICP";
export const ChainInternetCP = "Internet Computer";
export const ChainCKBTC = "ckBTC";
export const ChainCKETH = "ckETH";
export const ChainCKTestBTC = "ckTESTBTC";

export const BasicTheme = "Basic Layout";
export const StandardTheme = "Standard Layout";

export const setStorageData = (key: any, data: any) => {
  // const dataTemp = JSON.stringify(data);
  localStorage.setItem(key, data);
};

export const getStorageData = (key: string) => {
  try {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return item;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export function extractID(str: any) {
  const match = str.match(/(pos|paylink|sell|ecommerce)=(\d+)/);
  if (match) {
    const [, name, id] = match; // Destructuring the matched array
    return { name, id };
  }
  return null;
}

export const decodeContent = (pos: string) => {
  return atob(pos); // Use btoa to base64 encode the string
};

export const encodeContent = (pos: string) => {
  return btoa(pos);
};

export function shortenValue(value: any): any {
  return parseFloat(value.toFixed(4));
}

export const dateAndTimeFormat = () => {
  const timestamp = Date.now();
  const humanReadableDate = new Date(timestamp).toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
  return humanReadableDate;
};

export const copyContent = (textToCopy: string) => {
  copy(textToCopy, {
    debug: true,
    message: "Press #{key} to copy",
  });
  toastMessage("Copied!");
};

export const identifySuiDecimal = (getCoinName: any) => {
  switch (getCoinName) {
    case "FUD": {
      return 1_00_000;
    }
    case "FlowX": {
      return 1_00_000_000;
    }
    case "SUI COIN": {
      return 1_000_000_000;
    }
    case "USDC": {
      return 1_000_000;
    }
    case "Sacabam": {
      return 1_000_00;
    }
    case "NAVX": {
      return 1_000_000_000;
    }
    case "SUI": {
      return 1_000_000_000;
    }
    default: {
      return 1_000_000_000;
    }
  }
};

export const selectedChainIcon = (getCoinName: any) => {
  switch (getCoinName) {
    case "FUD": {
      return <Image src={FudIcon} preview={false} />;
    }
    case "FlowX": {
      return <Image src={FlowxIcon} preview={false} />;
    }
    case "Sacabam": {
      return <Image src={SacabamIcon} preview={false} />;
    }
    case "NAVX": {
      return <Image src={NavxIcon} preview={false} />;
    }
    case "USDC": {
      return <SolanaUsdcIcon />;
    }
    case "SUI COIN": {
      return <SuiIcon />;
    }
    case "SUI": {
      return <SuiIcon />;
    }
    case "Aptos": {
      return <AptosIcon />;
    }
    case "Venom": {
      return <VenomIcon />;
    }
    case "Solana": {
      return <SolanaIcon />;
    }
    case "SOL": {
      return <SolanaIcon />;
    }
    case "SOL-USDC": {
      return <SolanaUsdcIcon />;
    }
    case "JUP": {
      return <JupIcon />;
    }
    case "ICP": {
      return <ICPIcon />;
    }
    case "Internet Computer": {
      return <ICPIcon />;
    }
    case "ckBTC": {
      return <CkBTCIcon />;
    }
    case "ckETH": {
      return <CkETHIcon />;
    }
    case "ckTESTBTC": {
      return <CkBTCIcon />;
    }
  }
};

export const toastMessage = (data: any) =>
  toast(data, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export function shortenAddress(
  text: string,
  start = 5,
  end = 3,
  separator = "..."
): string {
  return !text
    ? ""
    : text.slice(0, start) + separator + (end ? text.slice(-end) : "");
}

interface GlobalMerchantContextType {
  globalMerchantId: string | null;
  setGlobalMerchantId: (merchantId: string | null) => void;
}

const GlobalMerchantContext = createContext<
  GlobalMerchantContextType | undefined
>(undefined);

export function GlobalMerchantProvider({ children }: { children: ReactNode }) {
  const [globalMerchantId, setGlobalMerchantId] = useState<string | null>(null);

  return (
    <GlobalMerchantContext.Provider
      value={{ globalMerchantId, setGlobalMerchantId }}
    >
      {children}
    </GlobalMerchantContext.Provider>
  );
}

export function useGlobalMerchant() {
  const context = useContext(GlobalMerchantContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalMerchant must be used within a GlobalMerchantProvider"
    );
  }
  return context;
}
