import { Button } from "antd";
import { shortenValue } from "utils/helper";

const ECShippingComponent = ({
  itemList,
  grandTotal,
  onCheckout,
  onBtnLoader,
}: any) => {
  return (
    <div className="ecShippingWrapper padd30 radius10 boxShadow">
      <p className="subHeading">Your Order</p>
      <div className="ecFlexSpaceWrapper ShippingHeading">
        <span className="subInnerHeading">Product</span>
        <span className="subInnerHeading">Subtotal</span>
      </div>
      {itemList.map((items: any) => (
        <div key={items.product.product_id} className="ecFlexSpaceWrapper">
          <span className="subInnerHeadingPara">
            {items.product.product_name}
          </span>
          <span className="subInnerHeading">
            {shortenValue(items.qty * items.product.price)} USD
          </span>
        </div>
      ))}
      <div className="ecFlexSpaceWrapper ShippingCost">
        <p className="subHeadingPara">Total</p>
        <p className="subHeading m0">{shortenValue(grandTotal)} USD</p>
      </div>
      <p className="subInnerHeadingPara ecShippingInfo">
        When placing an order, we ask for your email and phone number (both
        optional) as well as your address (required for delivery). This
        information is used solely to process your order.
      </p>
      <Button
        type="primary"
        className="commonButton"
        onClick={onCheckout}
        disabled={grandTotal === 0}
      >
        {onBtnLoader ? "Loading..." : "Place Order"}
      </Button>
    </div>
  );
};

export default ECShippingComponent;
