/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Button, Image } from "antd";
import { useBasket } from "containers/ProductContainer/constant";
import { baseImageURL } from "utils/env";
import { FilterIcon, SearchIcon } from "svgIcon";
import MonogramIcon from "assets/images/mongram.svg";
import { toastMessage } from "utils/helper";

const ProductListComponent = ({
  viewName,
  productList,
  catClick,
  setCatClick,
  newOne,
  setNewOne,
}: any) => {
  const { addToBasket, removeFromBasket, itemList } = useBasket();
  const [isHaveSubCategory, setIsHaveCategory] = useState(false);
  const [filterProductList, setFilterProductList] = useState<any[]>([]);
  const [labelList, setLabelList] = useState<string[]>([]);
  const [addedToCart, setAddedToCart] = useState<{
    [productId: string]: boolean;
  }>({});

  const handleCartAction = (productId: string, product: any) => {
    if (addedToCart[productId]) {
      removeFromCart(productId);
    } else {
      addToCart(productId, product);
    }
  };

  const addToCart = (productId: string, product: any) => {
    addToBasket(product);
    setAddedToCart((prevAddedToCart) => ({
      ...prevAddedToCart,
      [productId]: true,
    }));
    toastMessage("Item added to basket");
  };

  const removeFromCart = (productId: string) => {
    removeFromBasket(productId);
    setAddedToCart((prevAddedToCart) => ({
      ...prevAddedToCart,
      [productId]: false,
    }));
    toastMessage("Item removed from basket");
  };

  const handleSubCategoryClick = (clickedCategory: any) => {
    setCatClick(clickedCategory);
    setNewOne(2);
  };

  useEffect(() => {
    if (catClick && catClick.category_id) {
      setIsHaveCategory(false);
      if (catClick.category_id === 99001) {
        setFilterProductList(productList);
      } else {
        if (
          catClick &&
          catClick.sub_categories &&
          catClick.sub_categories.length > 0
        ) {
          newOne === 1
            ? setLabelList([catClick.category_name])
            : setLabelList([...labelList, catClick.category_name]);
          setIsHaveCategory(true);
          setFilterProductList([]);
        } else {
          const filteredProducts = productList.filter((product: any) => {
            return product.categories.some((category: any) => {
              return category.name === catClick.category_name;
            });
          });
          setFilterProductList(filteredProducts);
          setIsHaveCategory(false);
        }
      }
    }
  }, [catClick]);

  useEffect(() => {
    productList.forEach((filter: any) => {
      // Assuming you want to check if there is a matching item in itemList
      const hasMatchingItem = itemList.some(
        (item: any) => item.product.product_id === filter.product_id
      );
      if (hasMatchingItem) {
        setAddedToCart((prevAddedToCart) => ({
          ...prevAddedToCart,
          [filter.product_id]: true,
        }));
      }
    });
  }, []);

  return (
    <>
      <div
        className={`${
          viewName === "ecommerce"
            ? "ecContainer productListWrapper"
            : "productListWrapper"
        }`}
      >
        {isHaveSubCategory ? (
          <>
            {viewName === "pos" && (
              <div className="productListHeader">
                <p className="mainHeading">{labelList.join(" > ")}</p>
                <input type="text" name="" id="" />
                <div className="searchIcon">
                  <SearchIcon />
                </div>
                <div className="filterIcon">
                  <FilterIcon />
                </div>
              </div>
            )}
            <div className="flexWrapper">
              {catClick &&
                catClick.sub_categories &&
                catClick.sub_categories.length > 0 &&
                catClick.sub_categories.map((item: any) => {
                  return (
                    <div
                      className="subCatListWrapper"
                      onClick={() => handleSubCategoryClick(item)}
                    >
                      <div className="productListImage">
                        {item.image_path !== null ? (
                          <Image
                            src={`${baseImageURL}${item.image_path}`}
                            alt="product image"
                          />
                        ) : (
                          <Image src={MonogramIcon} alt="product image" />
                        )}
                      </div>
                      <div className="productListContent">
                        <p className="subInnerHeadingPara">
                          {item.category_name}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <>
            {viewName === "pos" && (
              <div className="productListHeader">
                <p className="mainHeading">{catClick.category_name}</p>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Comming soon..."
                />
                <div className="searchIcon">
                  <SearchIcon />
                </div>
                <div className="filterIcon">
                  <FilterIcon />
                </div>
              </div>
            )}
            {viewName === "pos" ? (
              <div className="productListBody">
                {filterProductList &&
                  filterProductList.map((product: any) => (
                    <div
                      key={product.product_id}
                      className="productItemWrapper"
                    >
                      <div className="productItemInner">
                        <div className="productListImage">
                          {product.img !== null ? (
                            <Image
                              src={`${baseImageURL}${product.img}`}
                              alt="product image"
                            />
                          ) : (
                            <Image src={MonogramIcon} alt="product image" />
                          )}
                        </div>
                        <div className="productListContent">
                          <p className="subInnerHeadingPara">
                            {product.product_name}
                          </p>
                        </div>
                        <div className="productListBottom">
                          <p className="subInnerHeading">{product.price} USD</p>
                          <div className="disableBtnWrapper">
                            {product.qty <= 0 ? (
                              <Button className="disableBtn">
                                Out Of Stock
                              </Button>
                            ) : (
                              <Button
                                className={
                                  addedToCart[product.product_id]
                                    ? "commonButtonSmall remove"
                                    : "commonButtonSmall add"
                                }
                                onClick={() =>
                                  handleCartAction(product.product_id, product)
                                }
                              >
                                {addedToCart[product.product_id]
                                  ? "Remove From Cart"
                                  : "Add To Cart"}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="productListBody">
                {filterProductList &&
                  filterProductList.map((product: any) => (
                    <div
                      key={product.product_id}
                      className="productItemWrapper"
                    >
                      <div className="productItemInner">
                        <div className="productListImage">
                          {product.img !== null ? (
                            <Image
                              src={`${baseImageURL}${product.img}`}
                              alt="product image"
                            />
                          ) : (
                            <Image src={MonogramIcon} alt="product image" />
                          )}
                        </div>
                        <div className="productListContent">
                          <p className="subInnerHeadingPara">
                            {product.product_name}
                          </p>
                          <p className="productInfo">{product.description}</p>
                        </div>
                        <div className="productListBottom">
                          <p className="subInnerHeading">{product.price} USD</p>
                          <div className="disableBtnWrapper">
                            {product.qty <= 0 ? (
                              <Button className="disableBtn">
                                Out Of Stock
                              </Button>
                            ) : (
                              <Button
                                className={
                                  addedToCart[product.product_id]
                                    ? "commonButtonSmall remove"
                                    : "commonButtonSmall add"
                                }
                                onClick={() =>
                                  handleCartAction(product.product_id, product)
                                }
                              >
                                {addedToCart[product.product_id]
                                  ? "Remove From Cart"
                                  : "Add To Cart"}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ProductListComponent;
