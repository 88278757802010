import { getPaymentInIt } from "apiServices/app";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Image, Spin } from "antd";
import axios from "axios";
import { baseURL } from "utils/env";
import SUCCESS from "assets/images/paymentSuccess.gif";
import { toastMessage } from "utils/helper";
import "./style.css";

const PaymentComponent = () => {
  const [completedContent, setCompletedContent] = useState<any>({});
  const [showLoader, setShowLoader] = useState(false);
  const history = useHistory();

  const dateAndTimeFormat = () => {
    const timestamp = Date.now();
    const humanReadableDate = new Date(timestamp).toLocaleString("sv-SE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    return humanReadableDate;
  };

  const completedApi = async (responseData: any, getInput: any) => {
    const getDateAndTime = await dateAndTimeFormat();
    const merchantObject = {
      amount: responseData.amount,
      client_address: " ",
      coin: " ",
      coin_amount: responseData.amount,
      payment_id: responseData.order_id,
      date_time: `${getDateAndTime}`,
      digest: " ",
      status: "completed",
      order_type: "Ecommerce",
      merchant_email: "test@pocketpay.finance",
      merchant_name: "pocketpay",
      merchant_address: " ",
      logo_path: "",
      network: " ",
      business_name: "demo business",
    };
    try {
      axios
        .put(`${baseURL}merchant_orders/${getInput}`, merchantObject)
        .then((response) => {});
    } catch (error) {}
  };

  const getPaymentInItDetails = (id: any) => {
    setShowLoader(true);
    try {
      getPaymentInIt(id)
        .then((response) => {
          const responseData = response.data || response;
          console.log("responseData", responseData);
          if (
            responseData &&
            responseData.merchant_id &&
            responseData.is_third_party === 1
          ) {
            setShowLoader(false);
            setCompletedContent(responseData);
            if (responseData.status === "completed") {
              const modifiedInput = responseData.failed_url.replace(
                "https://ecom.demo.pocketpay.finance/",
                ""
              );
              console.log('modifiedInput',modifiedInput);
              if(modifiedInput){
                completedApi(responseData, modifiedInput);
              }
            }
          } else {
            setShowLoader(false);
            toastMessage("Its not a correct url");
            history.push("/");
          }
        })
        .catch((error) => {
          toastMessage(error);
          setShowLoader(false);
        });
    } catch (error: any) {}
  };

  const fetchData = async () => {
    try {
      const input = window.location.pathname;
      const modifiedInput = input.replace("/payment/", "");
      modifiedInput && getPaymentInItDetails(modifiedInput);
    } catch (error) {
      console.log("Something went wrong");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="successWrapper">
      {showLoader ? (
        <div className="loaderCenterWrapper">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : Object.keys(completedContent).length > 0 &&
        completedContent.status == "completed" ? (
        <div className="succesContentWrapper">
          <div className="successHeader">
            <div className="successAnimation">
              <Image src={SUCCESS} preview={false} />
            </div>
            <p className="mainHeading">
              Your order has already <br />
              been placed
            </p>
          </div>
          <div className="successBody">
            <div className="successBodyContent">
              <p className="subInnerHeadingPara">Merchant Name : </p>
              <p className="subInnerHeading">
                {completedContent.business_name}
              </p>
            </div>
            <div className="successBodyContent">
              <p className="subInnerHeadingPara">Amount : </p>
              <p className="subInnerHeading">{completedContent.coin_amount}</p>
            </div>
            <div className="successBodyContent">
              <p className="subInnerHeadingPara">Order Id : </p>
              <p className="subInnerHeading">{completedContent.payment_id}</p>
            </div>
          </div>
          <div className="successFooter">
            <Button onClick={() => history.push("/Mzg=")}>Back To Main</Button>
          </div>
        </div>
      ) : (
        <div className="successHeader">
          <p className="mainHeading">OOPS! Transaction Failed</p>
        </div>
      )}
    </div>
  );
};

export default PaymentComponent;
