import { Image } from "antd";
import React from "react";
import { baseImageURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.svg";

const CategoryComponent = ({
  viewName,
  categoryList,
  setCatClick,
  catClick,
  showSideCategories,
  setNewOne,
}: any) => {
  return (
    <>
      {viewName === "pos" ? (
        <div
          className={`${showSideCategories
            ? "categoryWrapper activeCategoryWrapper"
            : "categoryWrapper"
            }`}
        >
          <div className="categoryHeader">
            <div className="categoryProfile">
              <div className="categoryProfileImg">
                <img src={MonogramIcon} alt="" />
              </div>
              <div className="categoryProfileContent">
                <p className="subInnerHeading">PocketPay</p>
              </div>
            </div>
          </div>
          <div className="categoryContent">
            <p className="subHeading">Categories</p>
            <div className="categoryTab">
              {categoryList.map((item: any, index: any) => {
                return (
                  <div
                    className={
                      catClick.category_id === item.category_id
                        ? "active categoryTabs"
                        : "categoryTabs"
                    }
                    key={index}
                    onClick={() => {
                      setCatClick(item);
                      setNewOne(1);
                    }}
                  >
                    <div className="categoryTabsImg">
                      {item.image_path !== null ? (
                        <Image
                          src={`${baseImageURL}${item.image_path}`}
                          alt="category image"
                          preview={false}
                        />
                      ) : (
                        <Image
                          src={MonogramIcon}
                          alt="category image"
                          preview={false}
                        />
                      )}
                    </div>
                    <p className="subInnerHeading">{item.category_name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="ecTabs">
          <div className="ecTabsInner">
            {categoryList.map((item: any, index: any) => {
              return (
                <div
                  className={
                    catClick.category_id === item.category_id
                      ? "active categoryTabs"
                      : "categoryTabs"
                  }
                  key={index}
                  onClick={() => {
                    setCatClick(item);
                    setNewOne(1);
                  }}
                >
                  <p className="subInnerHeading">{item.category_name}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryComponent;
