import React from "react";
const BackgroundVideo = ({ videoSource, children }: any) => {
  return (
    <>
      <video autoPlay={true} muted loop className="homeVideoBg">
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {children}
    </>
  );
};
export default BackgroundVideo;
