import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import {
  getMerchantAddress,
  getMerchantEcommerce,
  getMerchantSetting,
} from "apiServices/app";
import {
  BasicTheme,
  selectedChainIcon,
  toastMessage,
  useGlobalMerchant,
} from "utils/helper";
import ECBasicComponent from "../../ECTheme/ECBasicComponent";
import ECAdvanceComponent from "../../ECTheme/ECAdvanceComponent";
import { ToastContainer } from "react-toastify";

const ECFrontComponent = () => {
  const { setGlobalMerchantId } = useGlobalMerchant();
  const [ecommerceContent, setEcommerceContent] = useState<any>({});
  const [merchantContent, setMerchantContent] = useState<any>({});
  const [chainList, setChainList] = useState<any>([]);

  const managePathname = window.location.pathname;

  const onGetMerchantAddress = (getMerchantId: any) => {
    try {
      getMerchantAddress(getMerchantId)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData.length > 0) {
            const activeNetworks = responseData.filter(
              (network: any) => network.is_active === 1
            );
            const reverseNetworks = activeNetworks.reverse();
            const items = reverseNetworks.map((item: any) => {
              return (
                <Menu.Item key={item.id.toString()} className="chainList">
                  <div>{selectedChainIcon(item.blockchain_name)}</div>
                  <p>{item.blockchain_name}</p>
                </Menu.Item>
              );
            });
            setChainList(items);
          } else {
            toastMessage("You don't have blockchain profile");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {}
  };

  const onGetEcommerceData = (getUserKey: any) => {
    try {
      getMerchantEcommerce(getUserKey)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData !== null && responseData.success !== false) {
            if (responseData && responseData.success == false) {
              toastMessage("You dont have any profile");
            } else {
              setEcommerceContent(responseData);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {}
  };

  const onGetMerchantDetails = (getUserKey: any) => {
    try {
      getMerchantSetting(getUserKey)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData !== null && responseData.success !== false) {
            if (responseData && responseData.success == false) {
              toastMessage("You dont have any profile");
            } else {
              setGlobalMerchantId(responseData.merchant_id);
              onGetEcommerceData(responseData.merchant_id);
              onGetMerchantAddress(responseData.merchant_id);
              setMerchantContent(responseData);
              localStorage.setItem("ec-email", responseData.merchant_email);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {}
  };

  useEffect(() => {
    try {
      const decryptValue = atob("Mzg=");
      onGetMerchantDetails(decryptValue);
    } catch (error) {
      console.log("Something went wrong");
    }
  }, [managePathname]);

  console.log("here");

  return (
    <>
      <ToastContainer />
      {Object.keys(merchantContent).length > 0 &&
        Object.keys(ecommerceContent).length > 0 &&
        (ecommerceContent.ecom_theme == BasicTheme ? (
          <div className="ecBasicWrapper">
            <ECBasicComponent
              ecommerceContent={ecommerceContent}
              chainList={chainList}
              merchantContent={merchantContent}
            />
          </div>
        ) : (
          <div className="ecAdvanceWrapper">
            <ECAdvanceComponent
              ecommerceContent={ecommerceContent}
              chainList={chainList}
              merchantContent={merchantContent}
            />
          </div>
        ))}
    </>
  );
};

export default ECFrontComponent;
