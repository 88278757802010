import React, { useState } from "react";
import { Form, Input, Row, Col, Checkbox, Button } from "antd";

interface AddressFormComponentProps {
  formData: any;
  formType: "billing" | "shipping";
  isBothSame: any;
  onChange: any;
  initialValues: any;
  setInitialValues: any;
}

const AddressFormComponent: React.FC<AddressFormComponentProps> = ({
  formData,
  formType,
  isBothSame,
  onChange,
  initialValues,
  setInitialValues,
}) => {
  const handleInput = (name: string, value: string) => {
    setInitialValues({ ...initialValues, [name]: value });
  };

  const formFields = [
    {
      name: formType === "shipping" ? `first_name` : `sfirst_name`,
      label: "First Name",
    },
    {
      name: formType === "shipping" ? `last_name` : `slast_name`,
      label: "Last Name",
    },
    {
      name: formType === "shipping" ? `address_one` : `saddress_one`,
      label: "Address 1",
    },
    {
      name: formType === "shipping" ? `address_two` : `saddress_two`,
      label: "Address 2",
    },
    {
      name: formType === "shipping" ? `country` : `scountry`,
      label: "Country",
    },
    {
      name: formType === "shipping" ? `zip_code` : `szip_code`,
      label: "Zip Code",
    },
    {
      name: formType === "shipping" ? `city` : `scity`,
      label: "City",
    },
    {
      name: formType === "shipping" ? `state` : `sstate`,
      label: "State",
    },
    {
      name: formType === "shipping" ? `phone` : `sphone`,
      label: "Phone",
    },
    {
      name: formType === "shipping" ? `email` : `semail`,
      label: "Email",
    },
  ];

  const pairs = [];
  for (let i = 0; i < formFields.length; i += 2) {
    pairs.push(formFields.slice(i, i + 2));
  }

  return (
    <Form
      form={formData}
      name={formType}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={initialValues}
      autoComplete="off"
      layout="vertical"
    >
      {pairs.map((pair, index) => (
        <Row key={index} gutter={16}>
          {pair.map(({ name, label }) => (
            <Col span={12}>
              <Form.Item
                name={`${name}`}
                label={label}
                rules={[
                  {
                    required: true,
                    message: `Please input your ${label}!`,
                  },
                ]}
              >
                <Input
                  placeholder={`Enter ${label}`}
                  onChange={(e) => handleInput(`${name}`, e.target.value)}
                />
              </Form.Item>
            </Col>
          ))}
        </Row>
      ))}
      <Row>
        <Col span={24}>
          {formType === "shipping" && (
            <Form.Item>
              <Checkbox onChange={onChange} checked={isBothSame}>
                Make billing details same as shipping details
              </Checkbox>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default AddressFormComponent;
