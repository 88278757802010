import {
  Ecommerce,
  GetOrdersProducts,
  GetSingleOrder,
  Login,
  MerchantAddress,
  MerchantCategory,
  MerchantProduct,
  MerchantSetting,
  ThirdInItUrl
} from "utils/URLs";
import { get, getWithoutHeader, postWithoutHeader } from "utils/v2/api";

export const login = (data: any) => postWithoutHeader(`${Login}`, data);
export const getMerchantDetails = () => get(`${MerchantSetting}`);

export const getMerchantEcommerce = (data: any) =>
  getWithoutHeader(`${Ecommerce}/${data}`);
export const getMerchantAddress = (data: any) =>
  getWithoutHeader(`${MerchantAddress}/${data}`);
export const getSingleOrder = (data: any) =>
  getWithoutHeader(`${GetSingleOrder}/${data}`);
export const getSingleOrderProduct = (data: any) =>
  getWithoutHeader(`${GetOrdersProducts}/${data}`);
export const getMerchantSetting = (data: any) =>
  getWithoutHeader(`${MerchantSetting}/${data}`);
export const getMerchantProduct = (data: any) =>
  getWithoutHeader(`${MerchantProduct}/${data}`);
export const getMerchantCategory = (data: any) =>
  getWithoutHeader(`${MerchantCategory}/${data}`);
export const getPaymentInIt = (data: any) =>
  getWithoutHeader(`https://dashapi.pocketpay.finance/api/payment-init/${data}`);
