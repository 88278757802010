import { Button, Image } from "antd";
import BackgroundVideo from "./BackgroundVideo";
import { BackIcon } from "svgIcon";
import { baseImageURL } from "utils/env";
import HomeImage from "assets/images/homejpg.jpg";

const ECHeroSectionComponent = ({ setShowProducts, ecommerceContent }: any) => {
  function getFileType(filePath: string): "image" | "video" {
    const isVideo = filePath.toLowerCase().endsWith(".mp4");
    return isVideo ? "video" : "image";
  }

  const manageBgDetails = (getPath: any) => {
    if (getPath == null) {
      return <Image src={HomeImage} alt="background image" preview={false} />;
    } else {
      const fileType = getFileType(getPath);
      if (fileType === "image") {
        return (
          <Image
            src={HomeImage}
            alt="background image"
            preview={false}
          />
        );
      } else {
        const videoSource = `${baseImageURL}${getPath}`;
        return <BackgroundVideo videoSource={videoSource}></BackgroundVideo>;
      }
    }
  };

  return (
    <div className="ec-front-wrapper">
      {Object.keys(ecommerceContent).length > 0 && (
        <>
          <div className="homebg">
            {manageBgDetails(ecommerceContent.ecom_video_link)}
          </div>
          <div className="ec-front-content">
            <h1>{ecommerceContent.ecom_title}</h1>
            <p>{ecommerceContent.ecom_des}</p>
            <Button
              onClick={() => setShowProducts(true)}
              className="ecCommonButton"
            >
              Shop Now
              <BackIcon />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ECHeroSectionComponent;
