import React from "react";
import ECBillingComponent from "../ECBillingComponent";
import { CloseIcon } from "svgIcon";

const ECAddressComponent = ({ setShowShippingLayout }: any) => {
  return (
    <div className="ecContainer">
      <div className="ecAddressWrapper ">
        <div className="ecAddressTitleWrapper">
          <p className="mainHeading m0">Checkout</p>
          <p onClick={() => setShowShippingLayout(false)} className="cursorPointer"><CloseIcon /></p>
        </div>
        <ECBillingComponent />
      </div>
    </div>
  );
};

export default ECAddressComponent;
