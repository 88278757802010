import React, { useState } from "react";
import { PocketpayLogo, CancelIcon, ECartIcon, DropdownIcon } from "svgIcon";
import BasketComponent from "components/Basket/BasketComponent";
import { Button, Image, Menu } from "antd";
import { Dropdown, Space } from "antd";
import { BackIcon } from "svgIcon";
import BackgroundVideo from '../../ECView/ECHeroSectionComponent/BackgroundVideo'
import HomeImage from "assets/images/homejpg.jpg";
import { baseImageURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.svg";
import MonoIcon from "assets/images/mono.jpg";
import ECProductComponent from "components/Ecommerce/ECProductComponent";
import ECAddressComponent from "components/Ecommerce/ECBilling/ECAddressComponent";
import ECHeroSectionComponent from "components/Ecommerce/ECView/ECHeroSectionComponent";
import { useBasket } from "containers/ProductContainer/constant";
import { ToastContainer } from "react-toastify";

const ECAdvanceComponent = ({
  merchantContent,
  chainList,
  ecommerceContent,
}: any) => {
  const [showShippingLayout, setShowShippingLayout] = useState(false);
  const [showSideBasket, setShowSideBasket] = useState(false);

  const itemlist = useBasket();

  let length = 0;
  if (itemlist && itemlist.itemList) {
    length = itemlist.itemList.length;
  }

  function getFileType(filePath: string): "image" | "video" {
    const isVideo = filePath.toLowerCase().endsWith(".mp4");
    return isVideo ? "video" : "image";
  }

  const manageBgDetails = (getPath: any) => {
    if (getPath == null) {
      return <Image src={HomeImage} alt="background image" preview={false} />;
    } else {
      const fileType = getFileType(getPath);
      if (fileType === "image") {
        return (
          <Image
            src={HomeImage}
            alt="background image"
            preview={false}
          />
        );
      } else {
        const videoSource = `${baseImageURL}${getPath}`;
        return <BackgroundVideo videoSource={videoSource}></BackgroundVideo>;
      }
    }
  };

  return (
    <div
      className={"ec-component-wrapper inner ec-component-wrapper"}
    >
      <ToastContainer/>
      <div className="ec-header-wrapper">
        {Object.keys(merchantContent).length > 0 &&
        merchantContent.logo_path !== null ? (
          <Image
            src={MonoIcon}
            alt="profile Picture"
            preview={false}
          />
        ) : (
          <Image src={MonoIcon} alt="profile Picture" preview={false} />
        )}
        <div className="ec-header-wrapper-right">
          {/* {chainList && chainList.length > 0 && (
            <Dropdown overlay={<Menu>{chainList}</Menu>} className="dropDown">
              <div onClick={(e) => e.preventDefault()}>
                <Space className="subInnerHeading">
                  Supported Blockchain <DropdownIcon />
                </Space>
              </div>
            </Dropdown>
          )} */}
          {!showShippingLayout &&
            (showSideBasket ? (
              <div
                className="cross-icon"
                onClick={() => setShowSideBasket(false)}
              >
                <CancelIcon />
              </div>
            ) : (
              <div
                className={length > 0 ? "basket-icon active" : "basket-icon"}
                onClick={() => setShowSideBasket(true)}
              >
                <ECartIcon />
                <span className="ePNum">{length > 0 && length}</span>
              </div>
            ))}
        </div>
        {showSideBasket && (
          <BasketComponent
            setShowSideBasket={setShowSideBasket}
            showSideBasket={showSideBasket}
            setShowShippingLayout={setShowShippingLayout}
          />
        )}
      </div>
      <div className="mainPage ecProduct">
        {showShippingLayout ? (
          <ECAddressComponent setShowShippingLayout={setShowShippingLayout} />
        ) : (
          <>
            <>
          <div className="homebg">
            {manageBgDetails(ecommerceContent.ecom_video_link)}
          </div>
          <div className="ec-front-content">
            <h1>{ecommerceContent.ecom_title}</h1>
            <p>{ecommerceContent.ecom_des}</p>
          </div>
        </>
            <ECProductComponent
              ecommerceContent={ecommerceContent}
            />
          </>
        )}
      </div>
      <div className="ec-footer-wrapper">
        <PocketpayLogo />
        <p className="subInnerHeadingPara">@2024</p>
      </div>
    </div>
  );
};

export default ECAdvanceComponent;
