import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { baseURL, toBasePayURL } from "utils/env";
import { encodeContent, shortenValue, toastMessage } from "utils/helper";
import { Form, Input, Row, Col, Checkbox } from "antd";
import { useBasket } from "containers/ProductContainer/constant";
import ECShippingComponent from "../ECShippingComponent";
import { BackIcon } from "svgIcon";
import AddressFormComponent from "components/AddressFormComponent";

interface formComponentState {
  first_name: string;
  last_name: string;
  address_one: string;
  address_two: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  phone: string;
  email: string;
}

interface ECNextComponentState {
  sfirst_name: string;
  slast_name: string;
  saddress_one: string;
  saddress_two: string;
  scity: string;
  sstate: string;
  szip_code: string;
  scountry: string;
  sphone: string;
  semail: string;
}

const ECBillingComponent = ({ setHaveDoneAllField }: any) => {
  const { itemList } = useBasket();
  const history = useHistory();
  const [form] = Form.useForm();
  const [nextForm] = Form.useForm();
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [isBothSame, setIsBothSame] = useState(true);
  const [merchantId, setMerchantId] = useState("");
  const [titleName, setTitleName] = useState("");
  const [formData, setFormData] = useState<formComponentState>({
    first_name: "",
    last_name: "",
    address_one: "",
    address_two: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone: "",
    email: "",
  });
  const [nextFormData, setNextFormData] = useState<ECNextComponentState>({
    sfirst_name: "",
    slast_name: "",
    saddress_one: "",
    saddress_two: "",
    scity: "",
    sstate: "",
    szip_code: "",
    scountry: "",
    sphone: "",
    semail: "",
  });

  const onPocketEntery = async (getOrderAmount: any, getId: any) => {
    const formDataImage = new FormData();
    formDataImage.append("amount", getOrderAmount);
    formDataImage.append("redirect_url", "https://ecom.demo.pocketpay.finance");
    formDataImage.append(
      "failed_url",
      `https://ecom.demo.pocketpay.finance/${getId}`
    );
    try {
      const response = await fetch(
        `https://merchanttestnetapi.blockbolt.io/api/pocketpay-intrigation`,
        {
          method: "POST",
          body: formDataImage,
        }
      );
      const data = await response.json();
      if (data && data.paymenturl) {
        setOnBtnLoader(false);
        window.location.replace(data.paymenturl);
      }
    } catch (error) {}
  };

  const onStoreAddressDetails = (getOrderId: any, getOrderAmount: any) => {
    const newObject = {
      sfirst_name: isBothSame ? formData.first_name : nextFormData.sfirst_name,
      slast_name: isBothSame ? formData.last_name : nextFormData.slast_name,
      saddress_one: isBothSame
        ? formData.address_one
        : nextFormData.saddress_one,
      saddress_two: isBothSame
        ? formData.address_two
        : nextFormData.saddress_two,
      scity: isBothSame ? formData.city : nextFormData.scity,
      sstate: isBothSame ? formData.state : nextFormData.sstate,
      szip_code: isBothSame ? formData.zip_code : nextFormData.szip_code,
      scountry: isBothSame ? formData.country : nextFormData.scountry,
      sphone: isBothSame ? formData.phone : nextFormData.sphone,
      semail: isBothSame ? formData.email : nextFormData.semail,
      order_id: getOrderId,
      same_as_billing: isBothSame ? "yes" : "no",
      merchant_id: atob(merchantId),
    };
    try {
      axios
        .post(`${baseURL}add_billing_details`, {
          ...formData,
          ...newObject,
        })
        .then((response: any) => {
          const responseData = response.data || response;
          if (responseData && responseData.success === true) {
            if (titleName === "pos") {
              onPocketEntery(getOrderAmount, getOrderId);
              // const generateKey = encodeContent(`pos=${getOrderId}`);
              // setOnBtnLoader(false);
              // window.location.replace(`${toBasePayURL}/${generateKey}`);
            } else {
              onPocketEntery(getOrderAmount, getOrderId);
              // const generateKey = encodeContent(`ecommerce=${getOrderId}`);
              // setOnBtnLoader(false);
              // window.location.replace(`${toBasePayURL}/${generateKey}`);
            }
          } else {
            toastMessage("Something went wrong");
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    } catch (error: any) {}
  };

  const onStoreData = () => {
    if (grandTotal !== 0) {
      setOnBtnLoader(true);

      const modifyResp = itemList.map((item: any, i: any) => {
        return {
          categories: item.product.categories,
          img: item.product.img,
          price: item.product.price,
          product_id: item.product.product_id,
          name: item.product.product_name,
          sku: item.product.sku,
          oqty: item.qty,
        };
      });

      const getWalletEmail = localStorage.getItem("ec-email");
      const newObject = {
        merchant_id: atob(merchantId),
        amount: shortenValue(grandTotal),
        name: " ",
        client_address: " ",
        date_time: " ",
        digest: " ",
        product_detail: modifyResp,
        status: "pending",
        merchant_email: getWalletEmail,
        order_type: titleName === "pos" ? "POS" : "Ecommerce",
      };
      try {
        axios
          .post(`${baseURL}merchant_orders`, newObject)
          .then((response: any) => {
            if (response && response.data !== null && response.data.order_id) {
              sessionStorage.setItem("store-key", response.data.order_id);
              if (response.data.order_id) {
                onStoreAddressDetails(response.data.order_id, grandTotal);
              }
            }
          });
      } catch (error: any) {}
    }
  };

  const isFormDataEmpty = () => {
    for (const value of Object.values(formData)) {
      if (value === "") {
        return true;
      }
    }
    return false;
  };

  const isNextFormDataEmpty = () => {
    for (const value of Object.values(nextFormData)) {
      if (value === "") {
        return true; // If any value is empty, return true
      }
    }
    return false; // If no value is empty, return false
  };

  const onCheckout = async () => {
    if (!isBothSame) {
      if (isFormDataEmpty()) {
        toastMessage("Please fill in all fields");
        return;
      } else if (isNextFormDataEmpty()) {
        toastMessage("Please fill in all fields");
        return;
      } else {
        onStoreData();
      }
    } else {
      if (isFormDataEmpty()) {
        toastMessage("Please fill in all fields");
        return;
      } else {
        onStoreData();
      }
    }
  };

  function onChange(e: any) {
    setIsBothSame(!isBothSame);
  }

  const totalAmounts = itemList.map((item: any) => {
    return item.qty * item.product.price;
  });

  const grandTotal = totalAmounts.reduce(
    (acc: any, amount: any) => acc + amount,
    0
  );

  function getPathType(pathname: any) {
    if (pathname.startsWith("/pos/")) {
      return "pos";
    } else if (pathname.startsWith("/")) {
      return "ecommerce";
    } else {
      return "";
    }
  }

  useEffect(() => {
    const pathname = window.location.pathname;
    const orderType = getPathType(pathname);
    setTitleName(orderType);
    const parts = pathname.split("/");
    const number = parts[parts.length - 1];
    setMerchantId(number);
  }, []);

  return (
    <div className="ecAddressContentWrapper">
      <div className="ecBillingWrapper padd30 radius10 boxShadow">
        <p className="subHeading">Shipping Details</p>
        <AddressFormComponent
          formData={form}
          formType="shipping"
          isBothSame={isBothSame}
          onChange={onChange}
          initialValues={formData}
          setInitialValues={setFormData}
        />
        {!isBothSame && (
          <>
            <p className="subHeading">Billing Details</p>
            <AddressFormComponent
              formData={nextForm}
              formType="billing"
              isBothSame={isBothSame}
              onChange={onChange}
              initialValues={nextFormData}
              setInitialValues={setNextFormData}
            />
          </>
        )}
      </div>
      <ECShippingComponent
        itemList={itemList}
        grandTotal={grandTotal}
        onCheckout={onCheckout}
        onBtnLoader={onBtnLoader}
      />
    </div>
  );
};

export default ECBillingComponent;
