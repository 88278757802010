/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button, Image } from "antd";
import axios from "axios";
import { useBasket } from "containers/ProductContainer/constant";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { baseImageURL, baseURL, toBasePayURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.svg";
import { encodeContent, shortenValue, toastMessage } from "utils/helper";
import { getMerchantEcommerce } from "apiServices/app";

const BasketItemComponent = ({
  setShowShippingLayout,
  setShowSideBasket,
}: any) => {
  const { itemList, addToBasket, removeFromItem, removeFromBasket } =
    useBasket();
  const history = useHistory();
  const [merchantId, setMerchantId] = useState("");
  const [titleName, setTitleName] = useState("");
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [ecommerceContent, setEcommerceContent] = useState<any>({});

  const totalAmounts = itemList.map((item: any) => {
    return item.qty * item.product.price;
  });

  const grandTotal = totalAmounts.reduce(
    (acc: any, amount: any) => acc + amount,
    0
  );

  const handlePlusAction = (productId: string, product: any) => {
    addToBasket(product);
  };

  const handleMinusAction = (productId: string, product: any) => {
    itemList.map((product: any) => {
      if (product.product.product_id === productId) {
        if (product.qty > 1) {
          removeFromItem(productId);
        } else {
          removeFromBasket(productId);
        }
      }
    });
  };

  const onPocketEntery = async (getOrderAmount: any, getId: any) => {
    const formDataImage = new FormData();
    formDataImage.append("amount", getOrderAmount);
    formDataImage.append("redirect_url", "https://ecom.demo.pocketpay.finance");
    formDataImage.append(
      "failed_url",
      `https://ecom.demo.pocketpay.finance/${getId}`
    );
    try {
      const response = await fetch(
        `https://merchanttestnetapi.blockbolt.io/api/pocketpay-intrigation`,
        {
          method: "POST",
          body: formDataImage,
        }
      );
      const data = await response.json();
      if (data && data.paymenturl) {
        setOnBtnLoader(false);
        window.location.replace(data.paymenturl);
      }
    } catch (error) {}
  };

  const onStoreData = () => {
    const modifyResp = itemList.map((item: any, i: any) => {
      return {
        categories: item.product.categories,
        img: item.product.img,
        price: item.product.price,
        product_id: item.product.product_id,
        name: item.product.product_name,
        sku: item.product.sku,
        oqty: item.qty,
      };
    });

    const getWalletEmail = localStorage.getItem("ec-email");
    const newObject = {
      merchant_id: atob(merchantId),
      amount: shortenValue(grandTotal),
      name: " ",
      client_address: " ",
      date_time: " ",
      digest: " ",
      product_detail: modifyResp,
      status: "pending",
      merchant_email: getWalletEmail,
      order_type: titleName === "pos" ? "POS" : "Ecommerce",
    };
    try {
      axios
        .post(`${baseURL}merchant_orders`, newObject)
        .then((response: any) => {
          if (response && response.data !== null && response.data.order_id) {
            sessionStorage.setItem("store-key", response.data.order_id);
            if (response.data.order_id) {
              onPocketEntery(grandTotal, response.data.order_id);
            }
            // if (titleName === "pos") {
            //   const generateKey = encodeContent(
            //     `pos=${response.data.order_id}`
            //   );
            //   setOnBtnLoader(false);
            //   window.location.replace(`${toBasePayURL}/${generateKey}`);
            // } else {
            //   const generateKey = encodeContent(
            //     `ecommerce=${response.data.order_id}`
            //   );
            //   setOnBtnLoader(false);
            //   window.location.replace(`${toBasePayURL}/${generateKey}`);
            // }
          }
        });
    } catch (error: any) {}
  };

  const onCheckout = () => {
    if (grandTotal !== 0 && Object.keys(ecommerceContent).length > 0) {
      setOnBtnLoader(true);
      if (titleName === "pos") {
        onStoreData();
      } else {
        if (ecommerceContent.shipping_address == 1) {
          setShowShippingLayout(true);
          setShowSideBasket(false);
        } else if (ecommerceContent.shipping_address == 0) {
          onStoreData();
        }
      }
    }
  };

  function getPathType(pathname: any) {
    if (pathname.startsWith("/pos/")) {
      return "pos";
    } else if (pathname.startsWith("/")) {
      return "ecommerce";
    } else {
      return "";
    }
  }

  const onGetEcommerceData = (getUserKey: any) => {
    try {
      getMerchantEcommerce(getUserKey)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData !== null && responseData.success !== false) {
            if (responseData && responseData.success == false) {
              toastMessage("You dont have any profile");
            } else {
              setEcommerceContent(responseData);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {}
  };

  useEffect(() => {
    if (merchantId !== "") {
      onGetEcommerceData(atob(merchantId));
    }
  }, [merchantId]);

  useEffect(() => {
    const pathname = window.location.pathname;
    const orderType = getPathType(pathname);
    setTitleName(orderType);
    const parts = pathname.split("/");
    const number = parts[parts.length - 1];
    setMerchantId(number);
  }, []);

  return (
    <>
      <div className="basketItemWrapper">
        {itemList.map((items: any) => (
          <div className="basketConProWrapper">
            <div key={items.product_id} className="basketItem">
              <div className="basketItemPic">
                {items.product.img !== null ? (
                  <Image
                    src={`${baseImageURL}${items.product.img}`}
                    alt="product image"
                    preview={false}
                  />
                ) : (
                  <Image
                    src={MonogramIcon}
                    alt="product image"
                    preview={false}
                  />
                )}
              </div>
              <div className="basketItemContent">
                <span className="subInnerHeadingPara colorBlack">
                  {items.product.product_name}
                </span>
                <span className="subInnerHeading">
                  {shortenValue(items.qty * items.product.price)} USD
                </span>
              </div>
              <div className="basketItemBtnWrapper">
                {items.product.qty == items.qty ? (
                  <Button disabled>+</Button>
                ) : (
                  <Button
                    onClick={() =>
                      handlePlusAction(items.product.product_id, items.product)
                    }
                  >
                    +
                  </Button>
                )}
                <span className="subInnerHeadingPara">{items.qty}</span>
                {items.qty == 1 ? (
                  <Button disabled>-</Button>
                ) : (
                  <Button
                    onClick={() =>
                      handleMinusAction(items.product.product_id, items.product)
                    }
                  >
                    -
                  </Button>
                )}
              </div>
            </div>
            <span className="basketConProTitle">
              Available Quantity: {items.product.qty}
            </span>
          </div>
        ))}
      </div>
      <div className="basketCheckoutWrapper">
        <div className="basketAmountWrapper">
          <p className="subInnerHeadingPara">Total Amount:</p>
          <p className="subHeading m0">{shortenValue(grandTotal)} USD</p>
        </div>
        <Button
          type="primary"
          className="commonButton"
          onClick={onCheckout}
          disabled={grandTotal === 0}
        >
          {onBtnLoader ? "Loading..." : "Checkout"}
        </Button>
      </div>
    </>
  );
};

export default BasketItemComponent;
