import { Spin } from "antd";
import { getMerchantCategory, getMerchantProduct } from "apiServices/app";
import { useBasket } from "containers/ProductContainer/constant";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { toastMessage, useGlobalMerchant } from "utils/helper";
import { CancelIcon, CategoryIcon, CheckOutIcon } from "svgIcon";
import CategoryComponent from "components/Product/Category/CategoryComponent";
import ProductListComponent from "components/Product/ProductListComponent";

const ECProductComponent = ({ ecommerceContent }: any) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showSideCategories, setShowSideCategories] = useState(false);
  const [showSideBasket, setShowSideBasket] = useState(false);
  const { globalMerchantId } = useGlobalMerchant();
  const [newOne, setNewOne] = useState(0);
  const itemlist = useBasket();
  const [catClick, setCatClick] = useState({
    category_id: 99001,
    category_name: "All",
    image_path: null,
  });
  const [productList, setProductList] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);

  let length = 0;
  if (itemlist && itemlist.itemList) {
    length = itemlist.itemList.length;
  }

  const onMerchantCategory = () => {
    setShowLoader(true);
    try {
      getMerchantCategory(globalMerchantId)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData !== null && responseData.success !== false) {
            if (responseData.length === 0) {
              setShowLoader(false);
              toastMessage("OOPS you dont have any category");
            } else {
              setCategoryList([
                {
                  category_id: 99001,
                  category_name: "All",
                  image_path: null,
                },
                ...responseData,
              ]);
              setNewOne(1);
              getMerchantProducts();
            }
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toastMessage(error);
        });
    } catch (error: any) {}
  };

  const getMerchantProducts = () => {
    try {
      getMerchantProduct(globalMerchantId)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData !== null && responseData.success !== false) {
            if (responseData.length === 0) {
              setShowLoader(false);
              toastMessage("OOPS you dont have any product");
            } else {
              setProductList(responseData);
              setShowLoader(false);
            }
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toastMessage(error);
        });
    } catch (error: any) {}
  };

  useEffect(() => {
    if (globalMerchantId !== null && globalMerchantId !== undefined) {
      onMerchantCategory();
    } else {
      toastMessage("globalMerchantId not getting");
    }
  }, [globalMerchantId]);
  return (
    <>
      <ToastContainer />
      {showLoader ? (
        <div className="loaderCenterWrapper">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        <main className="productWrapper">
          <div className="productHeader">
            <div
              className="left"
              onClick={() => {
                setShowSideCategories(!showSideCategories);
                setShowSideBasket(false);
              }}
            >
              {showSideCategories ? <CancelIcon /> : <CategoryIcon />}
              <span className="mainHeadingPara">Categories</span>
            </div>
            <div
              className={length > 0 ? "right active" : "right"}
              onClick={() => {
                setShowSideBasket(!showSideBasket);
                setShowSideCategories(false);
              }}
            >
              {showSideBasket ? <CancelIcon /> : <CheckOutIcon />}
            </div>
          </div>
          {productList.length > 0 && (
            <>
              <CategoryComponent
                viewName={"ecommerce"}
                categoryList={categoryList}
                setCatClick={setCatClick}
                catClick={catClick}
                showSideCategories={showSideCategories}
                setNewOne={setNewOne}
              />
              <div className="productPage">
                {Object.keys(ecommerceContent).length > 0 && (
                  <div className="productHeadingWrapper">
                    <p className="mainHeading">{ecommerceContent.shop_title}</p>
                    <p className="mainHeadingPara">
                      {ecommerceContent.shop_des}
                    </p>
                  </div>
                )}
                <ProductListComponent
                  viewName={"ecommerce"}
                  setCatClick={setCatClick}
                  productList={productList}
                  catClick={catClick}
                  setNewOne={setNewOne}
                  newOne={newOne}
                />
              </div>
            </>
          )}
        </main>
      )}
    </>
  );
};

export default ECProductComponent;
